import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChatRoutingModule } from '@app/chat/chat-routing.module'
import { ChatPage } from '@app/chat/pages/chat/chat.page'
import { FormsModule } from '@angular/forms'
import { IconButtonComponent } from '@core/components/icon-button/icon-button.component'
import { IconComponent } from '@core/components/icon/icon.component'
import { SelectComponent } from '@core/components/select/select.component'
import { OptionComponent } from '@core/components/option/option.component'
import { AutocompleteComponent } from '@core/components/autocomplete/autocomplete.component'
import { ChatMenuComponent } from './components/chat-menu/chat-menu.component'
import { ChatListComponent } from './components/chat-list/chat-list.component'
import { ChatWindowComponent } from './components/chat-window/chat-window.component'
import { PopoverComponent } from '@core/components/popover/popover.component'
import { ButtonComponent } from '@core/components/button/button.component'
import { InputComponent } from '@core/components/input/input.component'
import { FormComponent } from '@core/components/form/form.component'
import { FormFieldComponent } from '@core/components/form-field/form-field.component'
import { TextareaComponent } from '@core/components/textarea/textarea.component'
import { ChatAddForm } from '@app/chat/forms/chat-add/chat-add.form'
import { ChatUpdateForm } from '@app/chat/forms/chat-update/chat-update.form'
import { TableComponent } from '@core/components/table/table.component'
import { TableHeaderComponent } from '@core/components/table-header/table-header.component'
import { TooltipDirective } from '@core/directives/tooltip.directive'
import { MessageCardComponent } from '@app/home/components/message-card/message-card.component'
import { AvatarComponent } from '@app/users/components/avatar/avatar.component'
import { ChatModalComponent } from './components/chat-modal/chat-modal.component'

@NgModule({
	declarations: [
		ChatPage,
		ChatMenuComponent,
		ChatListComponent,
		ChatWindowComponent,
		ChatAddForm,
		ChatUpdateForm,
		ChatModalComponent,
	],
	imports: [
		CommonModule,
		ChatRoutingModule,
		FormsModule,
		IconButtonComponent,
		IconComponent,
		SelectComponent,
		OptionComponent,
		AutocompleteComponent,
		PopoverComponent,
		ButtonComponent,
		InputComponent,
		FormComponent,
		FormFieldComponent,
		TextareaComponent,
		TableComponent,
		TableHeaderComponent,
		TooltipDirective,
		MessageCardComponent,
		AvatarComponent,
	],
	exports: [ChatModalComponent, ChatListComponent],
})
export class ChatModule {}
