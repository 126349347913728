import { Component, Input } from '@angular/core'
import { ModalService } from '@core/services/modal.service'
import { ModalComponent } from '@core/components/modal/modal.component'
import { ChatAddForm } from '@app/chat/forms/chat-add/chat-add.form'
import { ChatRoomReadSchema, LastMessageSchema } from '@app/chat/components/chat-list/chat-list.component'
import { FormControl } from '@angular/forms'
import { ChatService } from '@app/chat/services/chat.service'
import { ChatroomRead, ChatroomsService, UserRead, UsersService } from '@app/generated'
import { AuthService } from '@app/auth/services/auth.service'
import { NotificationService } from '@core/services/notification.service'
import { finalize, Subscription } from 'rxjs'

export interface Conversation {
	title: string
	last_message: string
}

export interface SearchSchema {
	chats: ChatRoomReadSchema[]
	users: UserRead[]
}

@Component({
	selector: 'chat-menu',
	templateUrl: './chat-menu.component.html',
	styleUrl: './chat-menu.component.scss',
})
export class ChatMenuComponent {
	@Input() isChatModal: boolean = false
	searchControl: FormControl<string | null> = new FormControl(null)
	current_userId: string = ''
	searchList: SearchSchema = { chats: [], users: [] }
	private resetSearchSubscription?: Subscription
	constructor(
		private modalService: ModalService,
		private chatService: ChatService,
		private usersService: UsersService,
		private authService: AuthService,
		private notificationService: NotificationService,
		private chatroomsService: ChatroomsService,
	) {}

	protected readonly textAddNewChat = $localize`New chat`
	protected readonly textSearch = $localize`Search...`
	protected readonly textSearchChats = $localize`Search for chats`

	ngOnInit() {
		const userid = this.authService.getUserIdFromToken()
		if (userid) this.current_userId = userid
		this.searchControl.valueChanges.subscribe(value => {
			if (value) this.handleUsersSearchValueChanges(value)
		})
		this.resetSearchSubscription = this.chatService.currentReset.subscribe(() => {
			this.searchControl.setValue(null)
		})
	}

	findChats() {
		this.chatService.notifyChatListUpdated()
	}

	async openNewChatModal() {
		const modal: ModalComponent<ChatAddForm> = await this.modalService.open(ChatAddForm)
		modal.title = $localize`Add New Chat`
		modal.iconName = 'chat-plus_outline'
		modal.width = '480px'
		modal.componentInstance.chatCreated.subscribe({
			next: () => {
				modal.close()
			},
		})
	}

	onScroll(event: any) {
		const element = event.target
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			this.chatService.notifyChatListScrolled()
		}
	}

	handleUsersSearchValueChanges(value: string): void {
		this.searchList.chats = []
		this.searchList.users = []
		this.usersService.getUsers(0, 5, value, false, 'desc', 'first_name').subscribe({
			next: response => {
				response.items.forEach(value => {
					if (value.id != this.current_userId) {
						this.searchList.users.push(value)
					}
				})
			},
			error: () => {
				this.notificationService.error($localize`Failed to fetch entities`)
			},
		})
		this.chatroomsService.getChatroomsForCurrentUser(0, 5, value, false, 'desc', undefined, false).subscribe({
			next: response => {
				response.items.forEach(async value => {
					if (value.id != this.current_userId) {
						this.searchList.chats.push(<ChatRoomReadSchema>{
							chatroom: value,
							chat_room_adminId: this.getChatroomAdmin(value),
							last_message: await this.fetchLastMessage(value.id),
							unridden_messages_count: value.new_messages_count,
						})
					}
				})
			},
			error: () => {
				this.notificationService.error($localize`Failed to fetch entities`)
			},
		})
	}

	async fetchLastMessage(chatroomId: string): Promise<LastMessageSchema> {
		return new Promise(resolve => {
			this.chatroomsService
				.getChatMessages(chatroomId, 0, 1, undefined, false, false)
				.pipe(finalize(() => {}))
				.subscribe({
					next: response => {
						if (response.items[0]) {
							resolve({ text: response.items[0].text, created_at: response.items[0].created_at })
						} else {
							resolve({ text: $localize`No messages in this chat`, created_at: null })
						}
					},
					error: () => {
						resolve({ text: $localize`No messages in this chat`, created_at: null })
					},
				})
		})
	}

	getChatroomAdmin(value: ChatroomRead) {
		const chatRoomAdminId: string[] = []
		value.users?.forEach(user => {
			if (user.user.id && user.is_chatroom_admin) {
				chatRoomAdminId.push(user.user.id)
			}
		})
		return chatRoomAdminId
	}
}
