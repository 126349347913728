import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { NotificationService } from '@core/services/notification.service'
import { ChatroomRead, ChatroomsService, UserRead } from '@app/generated'
import { ModalComponent } from '@core/components/modal/modal.component'
import { ModalService } from '@core/services/modal.service'
import { ChatUpdateForm } from '@app/chat/forms/chat-update/chat-update.form'
import { ConfirmationComponent } from '@core/components/confirmation/confirmation.component'
import { ChatRoomReadSchema } from '@app/chat/components/chat-list/chat-list.component'

export interface changeRoomId {
	chatroom: ChatRoomReadSchema
	isUpdating: boolean
}

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public currentRoomId: string = ''

	private idSource = new BehaviorSubject<changeRoomId | undefined>(undefined)
	currentId = this.idSource.asObservable()

	private leftChat = new BehaviorSubject<string | undefined>(undefined)
	leftChatNow = this.leftChat.asObservable()

	private updateChatListWindow = new BehaviorSubject<ChatRoomReadSchema | undefined>(undefined)
	currentUpdateChatListWindow = this.updateChatListWindow.asObservable()

	private addChatWithUser = new BehaviorSubject<UserRead | undefined>(undefined)
	currentaddChatWithUser = this.addChatWithUser.asObservable()

	private updateChatListSource = new BehaviorSubject<void>(undefined)
	chatListUpdated = this.updateChatListSource.asObservable()

	private scrollChatListSource = new BehaviorSubject<void>(undefined)
	chatListScrolled = this.scrollChatListSource.asObservable()

	private reset = new BehaviorSubject<void>(undefined)
	currentReset = this.reset.asObservable()

	private changeRoom = new BehaviorSubject<string>('')
	currentChangeRoom = this.changeRoom.asObservable()

	private collapsed = new Subject<boolean>()
	currentCollapsed = this.collapsed.asObservable()

	private readonly onDestroy = new Subject<void>()

	constructor(
		private notificationService: NotificationService,
		private chatroomsService: ChatroomsService,
		private modalService: ModalService,
	) {}

	ngOnDestroy() {
		this.onDestroy.next()
		this.onDestroy.complete()
	}

	changeId(id: ChatRoomReadSchema, isUpdating: boolean) {
		this.idSource.next({ chatroom: id, isUpdating: isUpdating })
		this.currentRoomId = id.chatroom.id
		this.toogleWindow('chat')
		this.informResetSearch()
	}

	notifyChatListUpdated() {
		this.updateChatListSource.next(undefined)
	}

	notifyChatListScrolled() {
		this.scrollChatListSource.next(undefined)
	}

	async openUpdateChatModal(conversation: ChatroomRead, isChatInfo: boolean) {
		if (!isChatInfo) {
			const modal: ModalComponent<ChatUpdateForm> = await this.modalService.open(ChatUpdateForm)
			modal.title = $localize`Update chat`
			modal.iconName = 'chat-square-dots'
			modal.width = '480px'
			modal.componentInstance.conversation = conversation
			modal.componentInstance.isChatInfo = isChatInfo
		} else {
			const modal: ModalComponent<ChatUpdateForm> = await this.modalService.open(ChatUpdateForm)
			modal.title = $localize`Chat Info`
			modal.iconName = 'chat-square-dots'
			modal.width = '480px'
			modal.componentInstance.conversation = conversation
			modal.componentInstance.isChatInfo = isChatInfo
		}
	}

	leaveChat(chatroomId: string) {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Danger`
			modal.componentInstance.message = $localize`Are you sure you want to leave this chat?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.chatroomsService.leaveChatroom(chatroomId).subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully left chat!`)
						this.informLeftChat(chatroomId)
						this.notifyChatListUpdated()
					},
					error: () => {
						this.notificationService.error($localize`Something goes wrong during leaving chat!`)
					},
				})
			}
		})
	}

	deleteChat(chatroomId: string) {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Danger`
			modal.componentInstance.message = $localize`Are you sure you want to delete this chat?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.chatroomsService.deleteChatroom(chatroomId).subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully deleted chat!`)
						this.informLeftChat(chatroomId)
						this.notifyChatListUpdated()
					},
					error: () => {
						this.notificationService.error($localize`Something goes wrong during deleting chat!`)
					},
				})
			}
		})
	}

	informLeftChat(id: string) {
		this.leftChat.next(id)
	}

	informResetSearch() {
		this.reset.next(undefined)
	}

	updateChatWindow(conversation: ChatRoomReadSchema | undefined) {
		if (conversation) {
			this.updateChatListWindow.next(conversation)
		} else {
			this.updateChatListWindow.next(undefined)
		}
	}

	informAddedChatWithUser(user: UserRead | undefined) {
		if (user) {
			this.addChatWithUser.next(user)
		} else {
			this.addChatWithUser.next(undefined)
		}
	}

	toogleWindow(window: string) {
		this.changeRoom.next(window)
	}

	changeCollapsed(collapsed: boolean) {
		this.collapsed.next(collapsed)
	}
}
