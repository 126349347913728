import { Component } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { AppService } from '@app/general/services/app.service'
import { ChatService } from '@app/chat/services/chat.service'

export interface ConversationMessage {
	from: string
	text: string
	date: string
}

export interface ConversationDetails {
	admin: string
	title: string
	participants: string[]
	messages: ConversationMessage[]
}

@Component({
	selector: 'chat.page',
	templateUrl: './chat.page.html',
	styleUrl: './chat.page.scss',
})
export class ChatPage {
	messageList: ConversationMessage[] = []
	conversationId: string | undefined = ''

	constructor(
		private loaderService: LoaderService,
		private appService: AppService,
		private chatService: ChatService,
	) {
		this.appService.title = $localize`Chat`
	}

	ngOnInit() {
		this.loaderService.disable()
		this.chatService.currentId.subscribe(id => (this.conversationId = id?.chatroom.chatroom.id))
	}
}
