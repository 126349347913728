import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ChatroomCreate, ChatroomsService, UserRead, UsersService } from '@app/generated'
import { NotificationService } from '@core/services/notification.service'
import { LoaderService } from '@core/services/loader.service'
import { finalize } from 'rxjs'
import { ModalService } from '@core/services/modal.service'
import { AuthService } from '@app/auth/services/auth.service'
import { ChatService } from '@app/chat/services/chat.service'

export interface AddChatFormSchema {
	name: FormControl<string>
	participants: FormControl<UserRead[]>
	admin: FormControl<string>
}

export const createChatForm = (): FormGroup<AddChatFormSchema> => {
	return new FormGroup<AddChatFormSchema>(<AddChatFormSchema>{
		admin: new FormControl<string>(''),
		participants: new FormControl([]),
		name: new FormControl<string>('', [Validators.required]),
	})
}

@Component({
	selector: 'chat-add-form',
	templateUrl: './chat-add.form.html',
	styleUrl: './chat-add.form.scss',
})
export class ChatAddForm {
	users: UserRead[] = []
	usersSearchValue: string = ''
	usersSearchLoading: boolean = false
	current_userId: string | null = ''

	protected readonly textWriteChatName = $localize`Write chat name...`
	protected readonly textAddChat = $localize`Add new chat`

	@Input() form: FormGroup<AddChatFormSchema> = createChatForm()
	@Output() chatCreated: EventEmitter<boolean> = new EventEmitter()

	constructor(
		private usersService: UsersService,
		private notificationService: NotificationService,
		private chatRoomService: ChatroomsService,
		private loaderService: LoaderService,
		private modalService: ModalService,
		private authService: AuthService,
		private chatService: ChatService,
	) {}

	ngOnInit() {
		this.current_userId = this.authService.getUserIdFromToken()
	}

	handleUsersSearchValueChanges(value: string): void {
		this.usersService.getUsers(0, 15, value, false, 'desc', 'first_name').subscribe({
			next: response => {
				this.users = []
				response.items.forEach(value => {
					if (value.id != this.current_userId) {
						this.users.push(value)
					}
				})
			},
			error: () => {
				this.notificationService.error($localize`Failed to fetch entities`)
			},
		})
	}

	chatCreate(): void {
		this.form.controls.participants.value.forEach(value => {
			console.log(value)
		})
		const data: ChatroomCreate = {
			chatroom_name: this.form.controls.name.value,
			user_ids: this.form.controls.participants.value.map(value => value.id),
		}
		if (!data || this.form.invalid) {
			this.notificationService.error('Form contains errors!')
			return
		}

		this.loaderService.enable()

		this.chatRoomService
			.createChatroom(data)
			.pipe(
				finalize(() => {
					this.loaderService.disable()
				}),
			)
			.subscribe({
				next: () => {
					if (this.modalService.modalRef?.componentInstance instanceof ChatAddForm) {
						this.modalService.modalRef.close()
					}
					this.chatCreated.emit(true)
					this.chatService.notifyChatListUpdated()
					this.notificationService.success($localize`Chat has been added`)
				},
				error: err => {
					let message = $localize`Was not able to add chat`
					if (err.code === 422) {
						message = $localize`Form contains errors`
					}
					this.notificationService.error(message)
					this.loaderService.disable()
				},
			})
		return
	}

	onSubmit() {
		this.form.markAllAsTouched()
		this.chatCreate()
	}
}
