<div class="chat-menu">
	<h2
		class="margin__bottom--medium"
		i18n>
		Chat
	</h2>
	<div class="chat-menu__controls">
		<epic-form-field class="full-width">
			<epic-input
				[placeholder]="textSearch"
				[control]="searchControl" />
			<epic-button
				[epic-tooltip]="textSearchChats"
				[epic-tooltip-background-color]="'var(--color__black)'"
				(onClick)="findChats()"
				end
				class="search">
				<epic-icon name="search" />
			</epic-button>
		</epic-form-field>
		<div class="add-new-chat-green">
			<epic-button
				class="add-new-chat"
				[epic-tooltip]="textAddNewChat"
				[epic-tooltip-background-color]="'var(--color__black)'"
				(click)="openNewChatModal()">
				<epic-icon name="group_add" />
			</epic-button>
		</div>
	</div>
	<div
		class="chat-menu__list"
		(scroll)="onScroll($event)">
		<chat-list
			[searchList]="searchList"
			[search]="searchControl.value" />
	</div>
</div>
