import { Component } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { ChatService } from '@app/chat/services/chat.service'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'

@Component({
	selector: 'app-chat-modal',
	templateUrl: './chat-modal.component.html',
	styleUrl: './chat-modal.component.scss',
})
export class ChatModalComponent {
	isVisible = true
	isCollapsed = true
	isWindow: boolean = false
	isMenu: boolean = true
	isUpdated: boolean = false
	isHidden: boolean = false
	toogleWindowSubscription?: Subscription
	toogleCollapsedSubscription?: Subscription

	constructor(
		private loaderService: LoaderService,
		private chatService: ChatService,
		private router: Router,
		// private windowModalService: WindowModalService,
	) {}

	protected readonly textOpenChatModal = $localize`Open chat`
	protected readonly textCloseChatModal = $localize`Close chat`

	ngOnInit() {
		this.loaderService.disable()
		this.router.events.subscribe(() => {
			if (this.router.url == '/chat') {
				this.isHidden = true
			} else {
				this.isHidden = false
			}
		})

		this.toogleWindowSubscription = this.chatService.currentChangeRoom.subscribe(value => {
			this.resetFlags()
			if (value == 'chat') {
				this.isWindow = true
				this.isUpdated = false
			} else if (value == 'update') {
				this.isWindow = true
				this.isUpdated = true
			} else if (value == 'menu') {
				this.isMenu = true
				this.isUpdated = false
			} else if (value == 'collapse') {
				this.isCollapsed = true
			}
		})
		this.toogleCollapsedSubscription = this.chatService.currentCollapsed.subscribe(value => {
			this.isCollapsed = value
		})
		this.isMenu = true
	}

	resetFlags() {
		this.isWindow = false
		this.isMenu = false
		this.isUpdated = false
	}

	collapseWindow() {
		this.isCollapsed = !this.isCollapsed
	}
}
