import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ChatroomCreate, ChatroomRead, ChatroomsService, UserRead, UsersService } from '@app/generated'
import { finalize, Subscription } from 'rxjs'
import { LoaderService } from '@core/services/loader.service'
import { NotificationService } from '@core/services/notification.service'
import { ChatService } from '@app/chat/services/chat.service'
import { AuthService } from '@app/auth/services/auth.service'
import { SocketsService } from '@app/general/services/sockets.service'
import { Logger } from '@app/general/utils/logger.util'
import { SearchSchema } from '@app/chat/components/chat-menu/chat-menu.component'
import { Router } from '@angular/router'

export interface LastMessageSchema {
	text: string
	created_at: string | null
}

export interface ChatroomIsUserChatroom {
	exists: boolean
	chat: ChatRoomReadSchema | undefined
}

export interface ChatRoomReadSchema {
	unridden_messages_count: number | undefined
	chatroom: ChatroomRead
	last_message: LastMessageSchema
	chat_room_adminId: string[]
}

@Component({
	selector: 'chat-list',
	templateUrl: './chat-list.component.html',
	styleUrl: './chat-list.component.scss',
})
export class ChatListComponent implements OnInit, OnDestroy {
	@Input() search: string | null = null
	@Input() searchList: SearchSchema = {
		chats: [],
		users: [],
	}
	@Output() conversationId: string = ''
	conversationList: ChatRoomReadSchema[] = []
	current_userId: string = ''
	offset: number = 0
	paggination: number = 25
	isChatModal: boolean = true
	isDashboard: boolean = true
	private conversationListSubscription?: Subscription
	private listScrollSubscription?: Subscription
	private addChatWithUserSubscription?: Subscription
	private readonly logger: Logger = new Logger(ChatListComponent.name, {
		infoBackground: '#aff332',
		infoColor: '#000000',
	})

	constructor(
		private chatroomsService: ChatroomsService,
		private loaderService: LoaderService,
		private usersService: UsersService,
		private notificationService: NotificationService,
		private authService: AuthService,
		private chatService: ChatService,
		private socketService: SocketsService,
		private router: Router,
	) {
		this.socketService.chatMessageCreated((messageID: any) => {
			if (messageID) {
				this.logger.info(`Received new message: ${JSON.stringify(messageID)}`)
				if (messageID.action == 'CREATED') {
					this.chatroomsService.getChatMessage(messageID.chatroom_id, messageID.message_id, false).subscribe({
						next: value => {
							const index = this.conversationList.findIndex(chatroom => chatroom.chatroom.id === messageID.chatroom_id)
							let messageCount: number = (this.conversationList[index].unridden_messages_count as number) + 1
							if (this.chatService.currentRoomId == this.conversationList[index].chatroom.id) messageCount = 0
							if (value.created_by?.id == this.current_userId) messageCount = 0

							if (index !== -1) {
								this.conversationList[index] = {
									...this.conversationList[index],
									last_message: {
										text: value.text,
										created_at: value.created_at,
									},
									unridden_messages_count: messageCount,
								}
								this.chatListSort()

								// Trigger change detection
								this.conversationList = [...this.conversationList]
							}
						},
					})
				}
				if (messageID.action) {
					this.logger.info(`Received new status of message: ${JSON.stringify(messageID)}`)
				}
			}
		})
		this.socketService.chatroomCreated((messageID: any) => {
			if (messageID) {
				if (messageID.action == 'CREATED') {
					this.logger.info(`Received new chatroom: ${JSON.stringify(messageID)}`)
					this.chatroomsService.getChatroom(messageID.chatroom_id).subscribe({
						next: value => {
							const lastMessage: LastMessageSchema = {
								text: $localize`No messages in this chat`,
								created_at: null,
							}
							this.conversationList.push(<ChatRoomReadSchema>{
								chatroom: value,
								chat_room_adminId: this.getChatroomAdmin(value),
								last_message: lastMessage,
								unridden_messages_count: value.new_messages_count,
							})
							this.chatListSort()
						},
					})
				}
				if (messageID.action == 'DELETED') {
					this.logger.info(`Received chatroom deletion message: ${JSON.stringify(messageID)}`)
					this.conversationList = this.conversationList.filter(
						conversation => conversation.chatroom.id !== messageID.chatroom_id,
					)
				}
				if (messageID.action == 'UPDATED') {
					this.logger.info(`Received chatroom update message: ${JSON.stringify(messageID)}`)
					let isInConversation = false

					this.conversationList.forEach(value => {
						if (value.chatroom.id == messageID.chatroom_id) {
							isInConversation = true
							if (this.isChatModal) {
								this.chatService.toogleWindow('menu')
							}
						}
					})

					if (isInConversation) {
						this.chatroomsService
							.getChatroom(messageID.chatroom_id)
							.pipe(finalize(() => {}))
							.subscribe({
								next: value => {
									const index = this.conversationList.findIndex(
										conversation => conversation.chatroom.id === messageID.chatroom_id,
									)

									if (index !== -1) {
										this.conversationList[index].chatroom = value
										this.conversationList[index].chat_room_adminId = this.getChatroomAdmin(value)
										this.chatListSort()
									} else {
										this.conversationList.push(<ChatRoomReadSchema>{
											chatroom: value,
											chat_room_adminId: this.getChatroomAdmin(value),
											last_message: { text: $localize`No messages in this chat`, created_at: null },
											unridden_messages_count: value.new_messages_count,
										})
										this.chatListSort()
									}
								},
								error: () => {
									this.conversationList = this.conversationList.filter(
										conversation => conversation.chatroom.id !== messageID.chatroom_id,
									)
									this.chatService.informLeftChat(messageID.chatroom_id)
								},
							})
					} else {
						this.chatroomsService.getChatroom(messageID.chatroom_id).subscribe({
							next: value => {
								const lastMessage: LastMessageSchema = {
									text: $localize`No messages in this chat`,
									created_at: null,
								}
								this.conversationList.push(<ChatRoomReadSchema>{
									chatroom: value,
									chat_room_adminId: this.getChatroomAdmin(value),
									last_message: lastMessage,
									unridden_messages_count: value.new_messages_count,
								})
								this.chatListSort()
							},
						})
					}
				}
			}
		})
	}

	ngOnInit() {
		this.offset = 0
		this.paggination = 25
		const userId = this.authService.getUserIdFromToken()
		if (userId) {
			this.current_userId = userId
		}
		this.conversationListSubscription = this.chatService.chatListUpdated.subscribe(() => {
			this.offset = 0
			this.fetchChatLists()
		})
		this.listScrollSubscription = this.chatService.chatListScrolled.subscribe(() => {
			this.fetchScrolledList()
		})
		this.addChatWithUserSubscription = this.chatService.currentaddChatWithUser.subscribe(value => {
			if (value) {
				this.startChatWithUser(value)
				this.chatService.informAddedChatWithUser(undefined)
			}
		})
		this.fetchChatLists()

		if (!this.isChatModal && this.router.url == '/home') {
			this.isDashboard = true
			console.log(this.isDashboard)
		}
	}

	ngOnDestroy() {
		this.conversationListSubscription?.unsubscribe()
		this.listScrollSubscription?.unsubscribe()
		this.addChatWithUserSubscription?.unsubscribe()
	}

	getChatroomAdmin(value: ChatroomRead) {
		const chatRoomAdminId: string[] = []
		value.users?.forEach(user => {
			if (user.user.id && user.is_chatroom_admin) {
				chatRoomAdminId.push(user.user.id)
			}
		})
		return chatRoomAdminId
	}

	fetchChatLists() {
		let search: string | undefined = undefined
		if (this.search) {
			search = this.search
		}
		this.chatroomsService
			.getChatroomsForCurrentUser(this.offset, this.paggination, search, false, 'desc', 'updated_at')
			.pipe(finalize(() => {}))
			.subscribe({
				next: async response => {
					const chatroomPromises = response.items.map(async value => {
						const chatRoomAdminId = this.getChatroomAdmin(value)
						const lastMessage: LastMessageSchema = await this.fetchLastMessage(value.id)
						return {
							chatroom: value,
							last_message: lastMessage,
							chat_room_adminId: chatRoomAdminId,
							unridden_messages_count: value.new_messages_count,
						}
					})
					const newConversations = await Promise.all(chatroomPromises)
					this.conversationList =
						this.offset === 0
							? this.removeDuplicates(newConversations)
							: this.removeDuplicates([...this.conversationList, ...newConversations])
					this.chatListSort()
				},
				error: () => {
					this.notificationService.error($localize`Failed to load chats`)
				},
			})
	}

	private removeDuplicates(conversations: ChatRoomReadSchema[]): ChatRoomReadSchema[] {
		const uniqueIds = new Set()
		return conversations.filter(conversation => {
			if (!uniqueIds.has(conversation.chatroom.id)) {
				uniqueIds.add(conversation.chatroom.id)
				return true
			}
			return false
		})
	}

	async fetchLastMessage(chatroomId: string): Promise<LastMessageSchema> {
		return new Promise(resolve => {
			this.chatroomsService
				.getChatMessages(chatroomId, 0, 1, undefined, false, false)
				.pipe(finalize(() => {}))
				.subscribe({
					next: response => {
						if (response.items[0]) {
							resolve({ text: response.items[0].text, created_at: response.items[0].created_at })
						} else {
							resolve({ text: $localize`No messages in this chat`, created_at: null })
						}
					},
					error: () => {
						resolve({ text: $localize`No messages in this chat`, created_at: null })
					},
				})
		})
	}

	chatListSort() {
		this.conversationList.sort((a, b) => {
			const aUpdatedAt = a.last_message.created_at || a.chatroom.updated_at
			const bUpdatedAt = b.last_message.created_at || b.chatroom.updated_at
			if (aUpdatedAt && bUpdatedAt) {
				return new Date(bUpdatedAt).getTime() - new Date(aUpdatedAt).getTime()
			} else {
				return 0
			}
		})
	}

	private fetchScrolledList() {
		this.offset += this.paggination
		this.fetchChatLists()
	}

	checkIfChatExistsWithUser(userId: string): ChatroomIsUserChatroom {
		let chatExists = false
		let userConversation: ChatRoomReadSchema | undefined = undefined
		this.conversationList.forEach(conversation => {
			const users = conversation.chatroom.users?.map(user => user.user.id)
			if (
				users &&
				users.length === 2 &&
				users.includes(this.current_userId) &&
				users.includes(userId) &&
				conversation.chatroom.is_private
			) {
				chatExists = true
				userConversation = conversation
			}
		})
		return { exists: chatExists, chat: userConversation }
	}

	startChatWithUser(user: UserRead) {
		const isUserChatroom: ChatroomIsUserChatroom = this.checkIfChatExistsWithUser(user.id)
		const chatroomCreate: ChatroomCreate = {
			chatroom_name: user.first_name + ' ' + user.last_name,
			user_ids: [user.id],
			is_private: true,
		}
		if (!isUserChatroom.exists) {
			this.chatroomsService.createChatroom(chatroomCreate).subscribe({
				next: async value => {
					const chatRoomAdminId = this.getChatroomAdmin(value)
					const lastMessage: LastMessageSchema = await this.fetchLastMessage(value.id)
					const chatroom: ChatRoomReadSchema = {
						chatroom: value,
						last_message: lastMessage,
						chat_room_adminId: chatRoomAdminId,
						unridden_messages_count: value.new_messages_count,
					}
					this.conversationList.push(chatroom)
					this.conversationList =
						this.offset === 0
							? this.removeDuplicates(this.conversationList)
							: this.removeDuplicates([...this.conversationList, ...this.conversationList])
					this.chatListSort()
					this.chatService.changeId(chatroom, false)
					this.chatService.informResetSearch()
					this.search = ''
					this.notificationService.success($localize`Started new chat`)
				},
				error: () => {
					this.notificationService.error($localize`Failed to start new chat`)
				},
			})
		} else {
			if (isUserChatroom.chat) {
				this.chatService.changeId(isUserChatroom.chat, false)
				this.chatService.informResetSearch()
			}
			this.search = ''
		}
	}
}
